import React from "react"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import CasinoCollection from "~/models/casino-collection"
import GameCollection from "~/models/game-collection"

import SEO from "~/ui/helpers/seo"
import Frame from "~/ui/layout/frame"
import CasinoToplistBlock from "~/ui/compositions/casino/toplist"
import PageCoverBlock from "~/ui/compositions/generic/page-cover"
import GameListBlock from "~/ui/compositions/game/list"
import BoxesBlock from "~/ui/compositions/generic/boxes"
import SequenceBlock from "~/ui/compositions/generic/sequence"
import ParagraphsBlock from "~/ui/compositions/generic/paragraphs"
import ChecklistBlock from "~/ui/compositions/generic/checklist"
import CollapsibleListBlock from "~/ui/compositions/generic/collapsible-list"
import CTA from "~/ui/compositions/generic/cta"

import { graphql } from "gatsby"
import {
  GamePageQuery,
  ContentfulCasinoCollection,
  ContentfulGameCollection,
  ContentfulPage,
} from "../../../types/graphql-types"

export const query = graphql`
  query GamePage($id: String, $slug: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    contentfulCasinoCollection(type: { eq: $slug }) {
      ...CasinoCollectionFields
    }
    contentfulGameCollection(type: { eq: $slug }) {
      ...GameCollectionFields
    }
  }
`

type Props = {
  data: GamePageQuery
}

const GamePage = ({ data }: Props) => {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const page = new Page(data.contentfulPage as ContentfulPage)
  const casinoCollection = new CasinoCollection(
    data.contentfulCasinoCollection as ContentfulCasinoCollection
  )
  const gameCollection = new GameCollection(
    data.contentfulGameCollection as ContentfulGameCollection
  )

  return (
    <Frame>
      <Analytics page={page} />
      <React.Fragment>
        <SEO page={page} />

        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "PageCover" && (
              <React.Fragment>
              <PageCoverBlock color="green" {...block.props} />
              <div className="mb-2 mt-2">
                <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0 px-4 md:px-10 xl:px-20">
                  <Breadcrumbs breadcrumbs={page.breadcrumbs} />
                </div>
              </div>
              </React.Fragment>
            )}
            {block.name == "CTA" && (
              <CTA className="lg:pb-0" {...block.props} />
            )}
            {block.name == "CasinoToplist" && (
              <CasinoToplistBlock
                rank
                collection={casinoCollection}
                {...block.props}
              />
            )}
            {block.name == "Paragraphs" && <ParagraphsBlock {...block.props} />}
            {block.name == "Checklist" && <ChecklistBlock {...block.props} />}
            {block.name == "GameList" && (
              <GameListBlock
                id="free-games"
                collection={gameCollection}
                {...block.props}
              />
            )}
            {block.name == "Boxes" && (
              <BoxesBlock background="yellow" {...block.props} />
            )}
            {block.name == "Sequence" && <SequenceBlock {...block.props} />}
            {block.name == "CollapsibleList" && (
              <CollapsibleListBlock {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default GamePage
