import React from "react"
import cx from "classnames"

import TitledSection from "~/ui/layout/titled-section"
import LocalImage from "~/ui/elements/image/local"
import sanitize from "~/helpers/sanitize"

type Props = {
  title: string
  description?: string
  background: string
  items: BoxProps[]
}

function Boxes({ title, description, items, background }: Props) {
  return (
    <TitledSection
      title={title}
      description={description}
      background={background}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 md:p-10 bg-blue-500 rounded-lg lg:rounded-2xl">
        {items.map((item, index) => {
          return <Box key={index} image={item.image} body={item.body} alt={item.alt}/>
        })}
      </div>
    </TitledSection>
  )
}

type BoxProps = {
  image: string
  body: string
  className?: string
  alt: string
}

function Box({ image, body, className, alt }: BoxProps) {
  return (
    <div className={cx("py-6 px-4 bg-white rounded-lg", className)}>
      <div className="flex justify-center mb-5">
        <img
          src={`/images/${image}`}
          alt={alt}
          className="block w-[280px] lg:w-[295px]"
          decoding="async"
          width={295}
        />
      </div>

      <p
        className="text-lg text-font-2"
        dangerouslySetInnerHTML={sanitize(body)}
      ></p>
    </div>
  )
}

export default Boxes
